<template>
  <nav class="navbar navbar-expand-md navbar-light bg-light justify-content-between" id="custom-nav">
    <div class="d-flex">
      <a class="navbar-brand" href="#"><strong>Verbosa</strong></a>
    </div>
    <div class="dropdown">
      <router-link to="/select"><button class="btn btn-secondary " id="quitButton">Terug</button></router-link>
    </div>
  </nav>
  <section id="lesson-content-section" class="d-lg-flex col-11 col-md-10 col-xl-8 mx-auto justify-content-center p-0">  
    <div id="lesson-content" class="mx-auto mx-lg-0 d-flex flex-column justify-content-between col-11 col-lg-6 order-2">
      <div class="w-100 d-flex justify-content-between p-0">
        <router-link v-if="lesson.previous" :to="{name: 'lesson'}">
          <svg @click="changeLesson(lesson.previous)" xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-arrow-left-short arrow m-0" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
          </svg>
        </router-link>
        <div class="no-arrow" v-else></div>
        <h3 class="m-0">{{ lesson.name }}</h3>
        <router-link v-if="lesson.next" :to="{name: 'lesson'}">
          <svg @click="changeLesson(lesson.next)" mlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-arrow-right-short arrow m-0" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
          </svg>
        </router-link>
        <div class="no-arrow" v-else></div>
      </div>
      <div class="w-100 my-3 text-center">
        <h3>{{ lesson.title }}</h3>
      </div>
      <div class="progress">
        <div class="progress-fill" :style="progress_style"></div>
      </div>
    </div>
    <div class="mx-auto mx-lg-0 col-11 col-lg-3 buttons-container p-0 order-1">
      <div class="d-flex justify-content-center justify-content-lg-start h-100 align-items-center p-0">
        <div class="w-100 d-md-flex d-lg-block text-center text-md-start my-4 my-lg-0 p-0">
          <router-link :to="{name: 'nouns', params: {method_id: $route.params.method_id, lesson_from: $route.params.lesson_id, lesson_to: $route.params.lesson_id, coming_from: 'lesson'}}" class="d-block d-md-inline col-10 col-sm-8 col-md-4 px-md-2 px-lg-0 mx-auto mx-md-0">
            <button class="btn lesson-button my-2 text-center text-lg-start mx-auto mx-md-0r">
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-lightbulb me-2" viewBox="0 0 16 16">
                <path d="M2 6a6 6 0 1 1 10.174 4.31c-.203.196-.359.4-.453.619l-.762 1.769A.5.5 0 0 1 10.5 13a.5.5 0 0 1 0 1 .5.5 0 0 1 0 1l-.224.447a1 1 0 0 1-.894.553H6.618a1 1 0 0 1-.894-.553L5.5 15a.5.5 0 0 1 0-1 .5.5 0 0 1 0-1 .5.5 0 0 1-.46-.302l-.761-1.77a1.964 1.964 0 0 0-.453-.618A5.984 5.984 0 0 1 2 6zm6-5a5 5 0 0 0-3.479 8.592c.263.254.514.564.676.941L5.83 12h4.342l.632-1.467c.162-.377.413-.687.676-.941A5 5 0 0 0 8 1z"/>
              </svg>
              Naamvallen
            </button>
          </router-link>
          <router-link :to="{name: 'verbs', params: {method_id: $route.params.method_id, lesson_from: $route.params.lesson_id, lesson_to: $route.params.lesson_id, coming_from: 'lesson'}}" class="d-block d-md-inline col-10 col-sm-8 col-md-4 px-md-2 px-lg-0 mx-auto mx-md-0">
            <button class="btn lesson-button my-2 text-center text-lg-start">
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-stoplights me-2" viewBox="0 0 16 16">
                <path d="M8 5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zm0 4a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zm1.5 2.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                <path d="M4 2a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2h2c-.167.5-.8 1.6-2 2v2h2c-.167.5-.8 1.6-2 2v2h2c-.167.5-.8 1.6-2 2v1a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-1c-1.2-.4-1.833-1.5-2-2h2V8c-1.2-.4-1.833-1.5-2-2h2V4c-1.2-.4-1.833-1.5-2-2h2zm2-1a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H6z"/>
              </svg>
              Werkwoorden
            </button>
          </router-link>
          <router-link v-if="lesson.learned" :to="{name: 'repeat', params: {method_id: $route.params.method_id, lesson_from: $route.params.lesson_id, lesson_to: $route.params.lesson_id, coming_from: 'lesson'}}" class="d-block d-md-inline col-10 col-sm-8 col-md-4 px-md-2 px-lg-0 mx-auto mx-md-0">
            <button @click="clearRepeat()" class="btn lesson-button my-2 text-center text-lg-start">
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-arrow-repeat me-2" viewBox="0 0 16 16">
                <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z"/>
                <path fill-rule="evenodd" d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"/>
              </svg>
              Herhalen
            </button>
          </router-link>
          <router-link v-else :to="{name: 'learn', params: {method_id: $route.params.method_id, lesson_from: $route.params.lesson_id, lesson_to: $route.params.lesson_id, coming_from: 'lesson'}}" class="d-block d-md-inline col-10 col-sm-8 col-md-4 px-md-2 px-lg-0 mx-auto mx-md-0">
            <button class="btn lesson-button my-2 text-center text-lg-start">
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-arrow-repeat me-2" viewBox="0 0 16 16">
                <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z"/>
                <path fill-rule="evenodd" d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"/>
              </svg>
              Leren
            </button>
          </router-link>
        </div>
      </div>
    </div>
  </section>
  <section id="words" class="d-lg-flex col-11 col-md-10 col-xl-8 mx-auto justify-content-center p-0 mb-5 mt-lg-5">
    <div class="col-11 col-lg-9 mx-auto p-0 text-center text-lg-start">
      <p>{{ lesson.wordcount }} termen</p>
      <h4 class="mb-5">Geleerd</h4>
      <div v-for="(word, i) in words" :key="i">
        <div v-if="word.learned">
          <div class="word-container w-100 mb-4 d-flex">
            <div class="col-6 ps-4 text-start">
              <p class="m-0">{{ word.name }}{{ word.addition }}</p>
            </div>
            <ol class="col-6 pe-4 text-start">
              <li v-for="(meaning) in word.meanings" :key="meaning">
                {{ meaning }}
              </li>
            </ol>
          </div>
        </div>
      </div>
      <h4 class="my-5 mx-auto mx-lg-0">Nog leren</h4>  
      <div v-for="(word, i) in words" :key="i">
        <div v-if="word.learned == false">
          <div class="word-container w-100 mb-4 d-flex">
            <div class="col-6 ps-4 text-start">
              <p class="m-0">{{ word.name }}{{ word.addition }}</p>
            </div>
            <ol class="col-6 pe-4 text-start">
              <li v-for="(meaning) in word.meanings" :key="meaning">
                {{ meaning }}
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div> 
  </section>
  <FooterComponent/>
</template>

<script>
import axios from 'axios'
import FooterComponent from './sub/FooterComponent.vue'
export default {
  name: 'LessonPage',
  components: {
    FooterComponent
  },
  props: {
    apiurl: String
  },    
  data: function() {
    return {
      progress_style: '',
      lesson: '',
      words: []
    }    
  },
  methods: {
    getLesson() {
      return new Promise(resolve => {
        const path = this.apiurl + '/lessons/' + this.$route.params.lesson_id;
        axios.get(path, {withCredentials: true})
          .then((res) => {
            this.lesson = res.data.lesson;
            resolve('succesful lesson reception')
          })
          .catch((error) => {
            this.$router.push({name: 'error', params: {error_code: -1}});  
            console.error(error);
          })
      })
    },
    getWords() {
      return new Promise(resolve => {
        const path = this.apiurl + '/lessons/' + this.$route.params.lesson_id + '/words';
        axios.get(path, {withCredentials: true})
          .then((res) => {
            this.words = res.data.words;
            resolve('successful words reception')
          })
          .catch((error) => {
            this.$router.push({name: 'error', params: {error_code: -1}}); 
            console.error(error);
          })
      })
    },
    clearRepeat() {
      const path = this.apiurl + '/clear_repeat';
      axios.get(path, {withCredentials: true})
        .catch((error) => {
          this.$router.push({name: 'error', params: {error_code: -1}}); 
          console.error(error);
        });
    },
    getProgressStyle() {
      let learned = 0;
      for (const word of this.words) {
        if (word.learned) {
          learned++;
        }
      }
      this.progress_style = 'width: ' + 100 * learned/this.words.length + '%';
    },
    async changeLesson(new_id) {
      this.$route.params.lesson_id = new_id;
      await this.getLesson();
      await this.getWords();
      this.getProgressStyle()
    }
  },
  async created() {
    await this.getLesson();
    await this.getWords();
    this.getProgressStyle()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
a:hover {
  color: black;
}

.arrow {
  color: black;
  margin: 0;
}

.no-arrow {
  width: 40px;
}

#lesson-content-section {
  margin-top: 60px;
}

.lesson-button {
  width: 200px;
}

.lesson-button:hover, .lesson-button-inside:hover {
  background-color: rgba(0, 0, 0, 0.2);
  border-color: transparent;
}

#lesson-content {
  padding: 40px;
  background-color: white;
  border-radius: 8px;
  box-shadow: -1px 1px 3px 1px rgb(0 0 0 / 4%);
}

.progress {
  background-color: white;
  padding: 0;
  height: 16px;
  border: 2px solid #141BA6;
  border-radius: 10px;
}

.progress-fill {
  height: 12px;
  background-color: #141BA6;
  border-radius: 10px;
}

html, 
body {
    color: #424242;
    font-family: Tahoma, Geneva, sans-serif;
    scroll-behavior: smooth;
    background-color: #F5F5F5 !important;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    overflow-x: hidden;
}

nav {
    margin: 0;
}
  
nav a {
  color: white !important;
}

#custom-nav {
    background-color: #141BA6 !important;
    width:100%;
    height: 80px;
    padding: 0 11%;
}
  
#navbarCollapse #navLi:hover {
    text-decoration: underline;
}
  
#quitButton {
    color: white !important;
    background-color: #1ED994 !important;
    padding: 11px 18px;
    border-radius: 6px;
    border: none !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
}

.test {
  height: auto !important;
}

.word-container {
    overflow: hidden;
    box-shadow: -1px 1px 3px 1px rgb(0 0 0 / 4%);
    border-radius: 10px;
    background-color: white;
    padding-top: 15px; /* very ugly solution */
}

.word-container p {
  font-size: 0.8rem;
}

.word-container li {
  font-size: 0.8rem;
}

@media (min-width: 992px) {
  .word-container p {
    font-size: 1rem;
  }

  .word-container li {
    font-size: 1rem;
  }
}
</style>
