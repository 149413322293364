<template>
    <div id="wrapper">
        <!-- navbar -->
        <nav id="navBar" class="navbar navbar-light bg-light d-flex justify-content-around">
            <div>
                <a href="#"><strong>Verbosa</strong></a>
            </div>
            <div class="d-flex">
                <a class="mx-3 d-none d-md-block" href="#students-indicator">Leerlingen</a>
                <a class="mx-3 d-none d-md-block" href="#teachers-indicator">Docenten</a>
            </div>
            <button id="nav-login" class="btn btn-secondary" data-bs-toggle="modal" data-bs-target="#login" @click="form_type = 'login', authenticated = null, weak_password = null, password_match = null, not_complete = null, invalid_email = null">Inloggen</button>
        </nav>
        <!-- login popup -->
        <div @focus="clearAuth()" class="modal fade" id="login" tabindex="-1" aria-labelledby="login" aria-hidden="true">
            <div class="modal-dialog">
                <div v-if="form_type == 'register'" class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Aanmelden</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" ref="closebutton"></button>
                    </div>
                    <div class="modal-body py-4">
                        <form @submit.prevent="register()">
                        <div class="mb-3">
                            <label for="register-email" class="form-label">Email adres</label>
                            <input type="email" class="form-control" v-model="reg_email" id="register-email" aria-describedby="emailHelp">
                        </div>
                        <div class="mb-3">
                            <label for="register-password" class="form-label">Wachtwoord</label>
                            <input type="password" class="form-control" v-model="reg_password" id="register-password">
                        </div>
                        <div class="mb-3">
                            <label for="repeat-password" class="form-label">Bevestig wachtwoord</label>
                            <input type="password" class="form-control" v-model="repeat_password" id="repeat-password">
                        </div>
                        <div class="mb-3">
                            <label for="account_key" class="form-label">Account code</label>
                            <input class="form-control" v-model="account_key" id="account_key">
                        </div>
                        <div class="d-flex">
                            <button type="submit" class="btn btn-primary" @click="weak_password = null, password_match = null, not_complete = null, invalid_email = null">Aanmelden</button>
                            <p class="ms-3 my-auto text-danger" v-if="authenticated == false">*vul een geldige account code in</p>
                            <p class="ms-3 my-auto text-danger" v-if="invalid_email">*email is al in gebruik</p>
                            <p class="ms-3 my-auto text-danger" v-if="weak_password">*kies een sterker wachtwoord</p>
                            <p class="ms-3 my-auto text-danger" v-if="password_match == false">*wachtwoorden komen niet overeen</p>
                            <p class="ms-3 my-auto text-danger" v-if="not_complete">*vul alle velden in</p>
                        </div>
                        <div class="d-flex mt-3">
                            <p class="me-2">Heb je al een account?</p>
                            <a @click="form_type = 'login', authenticated = null, weak_password = null, password_match = null, not_complete = null, invalid_email = null"><i>inloggen</i></a>
                        </div>
                        </form>
                    </div>
                </div>
                <div v-if="form_type == 'login'" class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Login</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" ref="closebutton"></button>
                    </div>
                    <div class="modal-body py-4">
                        <form @submit.prevent="login()">
                        <div class="mb-3">
                            <label for="login-email" class="form-label">Email adres</label>
                            <input type="email" class="form-control" v-model="email" id="login-email" aria-describedby="emailHelp">
                        </div>
                        <div class="mb-3">
                            <label for="login-password" class="form-label">Wachtwoord</label>
                            <input type="password" class="form-control" v-model="password" id="login-password">
                        </div>
                        <div class="d-flex">
                            <button type="submit" class="btn btn-primary" @click="authenticated = null, not_complete = null">Login</button>
                            <p class="ms-3 my-auto text-danger" v-if="authenticated == false">*verkeerde email of wachtwoord</p>
                            <p class="ms-3 my-auto text-danger" v-if="not_complete">*vul alle velden in</p>
                        </div>
                        <div class="d-flex mt-3">
                            <p class="me-2">Heb je nog geen account?</p>
                            <a @click="form_type = 'register', authenticated = null, weak_password = null, password_match = null, not_complete = null, invalid_email = null"><i>aanmelden</i></a>
                        </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <section id="main-section" class="d-lg-flex justify-content-center align-items-center bg-secondary">
            <div class="col-11 col-sm-10 col-md-8 col-lg-4 col-xl-3 mx-auto mx-lg-0 text-center">
                <h1 class="d-inline">Oefen alles van de klassieke talen met </h1>
                <h1 class="d-inline" id="main-verbosa">Verbosa</h1>
                <h4 class="mt-4 mb-3">Oefen op een efficiënte manier!</h4>
                <div class="d-flex justify-content-center mt-3">
                    <button id="main-login" class="btn btn-light mx-3" data-bs-toggle="modal" data-bs-target="#login" @click="form_type = 'register', authenticated = null, weak_password = null, password_match = null, not_complete = null">Beginnen</button>
                    <p class="mx-3 my-auto">Probeer het</p>
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-arrow-right-short d-none d-lg-block my-auto" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                    </svg>
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" id="arrow-down" class="bi bi-arrow-down-short d-lg-none mb-5" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z"/>
                </svg>
            </div>
            <div class="col-1"></div>
            <div id="preview-component" class="col-11 col-sm-10 col-md-8 col-lg-6 col-xl-4 mx-auto mx-lg-0">
                <PreviewComponent/>
            </div> 
        </section>
        <div id="students-indicator"></div>
        <section id="students" class="col-10 col-lg-9 col-xl-8 mx-auto text-center">
            <h1 class="mx-auto">Oefen woordjes én grammatica!</h1>
            <img class="mx-auto" height="35" src="../assets/underline_colored.png">
            <div class="d-md-flex align-items-center students-row">
                <div class="col-11 col-md-7 col-lg-6 text-center mb-5 mb-md-0 mx-auto">
                    <h2>Herhalen</h2>
                    <p>Op deze website kun je al je al je woordjes van Latijn en Grieks overzichtelijk leren en bijhouden. Daarnaast oefen je op je eigen tempo en het worden je antwoorden direct voor je nagekeken zodat je ziet wat je fout doet!</p>
                </div>
                <img class="col-8 col-md-6 col-lg-5 col-xl-4 mx-auto" src="../assets/index_plaatje1_colored.png">
            </div> 
            <div class="d-md-flex align-items-center students-row">
                <div class="col-11 col-md-7 col-lg-6 text-center mb-5 mb-md-0 mx-auto order-2">
                    <h2>Ervaring</h2>
                    <p>Deze website is gemaakt door gymnasiasten die precies weten wat leerlingen nodig hebben. Ze hebben zelf ervaring en weten precies hoe je het productiefst leert!</p>
                </div>
                <img class="col-8 col-md-6 col-lg-5 col-xl-4 mx-auto order-1" src="../assets/index_plaatje2_colored.png">
            </div> 
            <div class="d-md-flex align-items-center students-row">
                <div class="col-11 col-md-7 col-lg-6 text-center mb-5 mb-md-0 mx-auto">
                    <h2>Declinaties</h2>
                    <p>Op deze website kun je je woordjes laten overhoren in een toegepaste vorm. Dit houdt in dat je bij zelfstandige naamwoorden alle naamvallen kunt oefenen en bij werkwoorden alle vormen van elke tijd.</p>
                </div>
                <img class="col-8 col-md-6 col-lg-5 col-xl-4 mx-auto" src="../assets/index_plaatje3_colored.png">
            </div> 
        </section>
        <div id="teachers-indicator"></div>
        <section id="teachers">
            <div class="col-10 col-lg-9 col-xl-8 mx-auto d-md-flex align-items-center">
                <div class="col-11 col-md-7 col-lg-6 text-center mb-5 mb-md-0 mx-auto order-2">
                    <h2>Docenten</h2>
                    <p>Geef uw leerlingen meer mogelijkheden. Op deze website kunnen uw leerlingen zelf oefeningen samenstellen en het programma kijkt het voor ze na. Goed vertalen begint bij het onder de knie hebben van het herkennen van functies in een zin.</p>
                    <a href="mailto:support@verbosa.nl"><button id="teachers-contact" class="btn btn-secondary mx-auto">Contact</button></a>
                </div>
                <div class="col-8 col-md-6 col-lg-5 col-xl-4 mx-auto order-1">
                    <img class="w-100" src="../assets/index_plaatje4_colored.png">
                </div>
            </div> 
        </section>
        <section class="w-100" id="start-button-container">
            <h1 class="col-8 mx-auto text-center mb-4">De nieuwe methode gebruiken?</h1>
            <div class="col-8 mx-auto d-flex justify-content-center">
                <button id="start-button" class="btn btn-secondary" data-bs-toggle="modal" data-bs-target="#login" @click="form_type = 'register', authenticated = null, weak_password = null, password_match = null, not_complete = null, invalid_email = null">Beginnen</button>
            </div>
        </section>
        <FooterComponent/>
    </div>
</template>
<script>
import axios from 'axios'
import PreviewComponent from './sub/PreviewComponent.vue'
import FooterComponent from './sub/FooterComponent.vue'
import CryptoJS from 'crypto-js'
import passwordValidator from 'password-validator'
export default {
  name: 'LandingPage',
  components: {
    PreviewComponent,
    FooterComponent
  },
  props: {
    apiurl: String
  },
  data: function() {
    return {
        form_type: 'login',
        authenticated: null,
        email: '',
        password: '',
        not_complete: null,
        reg_email: '',
        reg_password: '',
        repeat_password: '',
        weak_password: null,
        password_match: null,
        invalid_email: null,
        account_key: '',
        modal: '',
        passphrase: 'Ma Linu is een held',
        schema: new passwordValidator()
    }    
  },
  methods: {
    clearAuth() {
        this.authenticated = null;
    },
    login: function () {
        clearTimeout(this.waiter)
        if (this.password == '' || this.email == '') {
            this.not_complete = true;
            return
        }
        const hash = CryptoJS.HmacSHA256(this.password, this.passphrase).toString(CryptoJS.enc.Base64)
        const path = this.apiurl + '/check_credentials';
        axios.post(path, {email: this.email, password: hash}, {withCredentials: true})
        .then(res => {
            if(res.data.authenticated) {
                this.$refs.closebutton.click()
                document.getElementsByClassName('modal-backdrop')[0].remove()
                this.$router.push({name: 'select'});          
            }
            else {
                this.authenticated = false;
            }
        })           
        .catch(error => {
            if (error.response.data.message == 'Incorrect arguments') {
                this.authenticated = false;
            }
            else {
                this.$router.push({name: 'error', params: {error_code: -1}}); 
            }
            console.log(error)
        })
    },
    register() {
        clearTimeout(this.waiter)
        if (this.reg_password == '' || this.repeat_password == '' || this.reg_email == '' || this.account_key == '') {
            this.not_complete = true;
            return
        }
        if (this.repeat_password != this.reg_password) {
            this.password_match = false;
            return
        }
        if (this.schema.validate(this.reg_password) == false) {
            this.weak_password = true;
            return
        }
        const password_hash = CryptoJS.HmacSHA256(this.reg_password, this.passphrase).toString(CryptoJS.enc.Base64)
        const key_hash = CryptoJS.HmacSHA256(this.account_key, this.passphrase).toString(CryptoJS.enc.Base64)
        const path = this.apiurl + '/register';
        axios.post(path, {email: this.reg_email, password: password_hash, code: key_hash}, {withCredentials: true})
        .then(res => {
            if(res.data.authenticated) {
                this.$refs.closebutton.click()
                document.getElementsByClassName('modal-backdrop')[0].remove()
                this.$router.push({name: 'select'});          
            }
        })           
        .catch(error => {
            if (error.response.data.message == 'Invalid registration code') {
                this.authenticated = false;
            }
            else if (error.response.data.message == 'Email address already in use') {
                this.invalid_email = true;
            }
            else {
                this.$router.push({name: 'error', params: {error_code: -1}}); 
            }
            console.log(error)
        })
    }              
  },
  mounted() {
    this.schema
    .is().min(10)
    .is().max(30)
    .has().uppercase()
    .has().lowercase()
    .has().digits(1)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#wrapper {
    background-color: #fbfbfb;
}

#navBar {
    background-color: #141BA6 !important;
    height: 80px !important;
}

#navBar a {
    color: white;
    text-decoration: none;
}

a:hover {
    text-decoration: none;
    cursor: pointer;
}

#nav-login {
    border: none;
    background-color: #1ED994;
    padding: 12px 23px;
    border-radius: 8px;
}

#main-section {
    background-color: #141BA6 !important;
    color: #F2F2F2;
    padding: 80px 0;
}

#main-verbosa {
    color: white;
    border-bottom: 3px solid #1ED994;
}

#main-login {
    border: none;
    background-color: #1ED994;
    padding: 12px 23px;
    border-radius: 8px;
    color: white;
}

#arrow-down {
    margin-left: 120px !important;
}

#preview-component {
    padding: 0;
    height: 540px;
    border-radius: 15px !important;
    box-shadow: -1px 1px 3px 1px rgb(0 0 0 / 4%);
}

#students {
    margin-top: 130px;
}

.students-row {
    margin-top: 130px;
}

#teachers {
    margin-top: 130px;
    background-color: #F2F2F2;
    padding: 100px 0;
}

#teachers-contact {
    border: none;
    background-color: #141BA6;
    padding: 12px 23px;
    border-radius: 8px;
    color: white;
}

#start-button-container {
    margin: 150px 0;
}

#start-button {
    background-color: #F4AA09;
    padding: 12px 23px;
    border-radius: 8px;
    border: none;
}
</style>
