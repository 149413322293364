<template>
  <form class="col-11 col-md-9 col-lg-8 col-xl-5 mt-5 mx-auto justify-content-between d-flex">
    <ol class="mt-3">
      <li v-for="(meaning, x) in correctanswer" :key="x">{{ meaning }}</li>
    </ol>
    <input @click="$emit('next')" class="submitButton text-center" value="verder">
    <!--<input class="questionButton text-center" value="?">-->
  </form>
</template>

<script>
export default {
  name: 'TextRevealComponent',
  props: {
    correctanswer: Array
  },
  created() {
    window.addEventListener("keypress", (event)=> {
      if (event.key === "Enter") {
        this.$emit('next');
      }
    });
  }
}
</script> 

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
input {
  font-size: 1.4rem;
  background-color: white;
  -webkit-box-shadow: -1px 1px 3px 1px rgba(0,0,0,0.07);
  -moz-box-shadow: -1px 1px 3px 1px rgba(0,0,0,0.07);
  box-shadow: -1px 1px 3px 1px rgba(0,0,0,0.07);
  height: 75px;
  border-radius: 15px;
  border: none !important;
}

.textInput {
  padding-left: 30px;
  width: 77%;
}

.right {
  background-color: green;
  color: white;
}

.wrong {
  background-color: red;
  color: white;
}

.submitButton {
  width: 20%;
}

/*.questionButton {
  width: 8%;
}*/

input:focus {
  outline: none !important;
}

.questionButton:hover, .submitButton:hover {
  cursor: pointer;
}

ol {
  width: 74%;
}

li {
  font-size: 1.4rem;
}
</style>
