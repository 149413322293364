<template>
  <form class="col-11 col-md-9 col-lg-8 col-xl-5 mt-5 mx-auto d-flex justify-content-between">   
    <input v-model="answer" id="text-input" class="textInput" autocomplete="off" type="text"> 
    <input @click="$emit('checkOpen', answer)" class="submitButton text-center" value="check">
    <!--<input class="questionButton text-center" value="?">-->
  </form>
</template>

<script>
export default {
  name: 'TextComponent',
  data: function() {
    return {
      answer: ''
    }    
  },
  created() {
    window.addEventListener("keypress", (event)=> {
      if (event.key === "Enter") {
        this.$emit('checkOpen', this.answer)
      }
    });
  },
  mounted() {
    document.getElementById("text-input").focus();
  }
}
</script> 

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
input {
  color: black;
  font-size: 1.4rem;
  background-color: white;
  -webkit-box-shadow: -1px 1px 3px 1px rgba(0,0,0,0.07);
  -moz-box-shadow: -1px 1px 3px 1px rgba(0,0,0,0.07);
  box-shadow: -1px 1px 3px 1px rgba(0,0,0,0.07);
  height: 75px;
  border-radius: 15px;
  border: none !important;
}

.textInput {
  padding-left: 30px;
  width: 77%;
}

.right {
  background-color: green;
  color: white;
}

.wrong {
  background-color: red;
  color: white;
}

.submitButton {
  width: 20%;
}

/*.questionButton {
  width: 8%;
}*/

input:focus {
  outline: none !important;
}

.questionButton:hover, .submitButton:hover {
  cursor: pointer;
}

ol {
  width: 74%;
}

li {
  font-size: 1.4rem;
}
</style>
