import { createRouter, createWebHistory } from 'vue-router'
import LandingPage from '../components/LandingPage.vue'
import ErrorPage from '../components/ErrorPage.vue'
import SelectPage from '../components/SelectPage.vue'
import LessonPage from '../components/LessonPage.vue'
import MeaningPage from '../components/MeaningPage.vue'
import FormsPage from '../components/FormsPage.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: LandingPage
  },
  {
    path: '/error/:error_code',
    name: 'error',
    component: ErrorPage
  },
  {
    path: '/select',
    name: 'select',
    component: SelectPage
  },
  {
    path: '/lesson/:method_id/:lesson_id',
    name: 'lesson',
    component: LessonPage
  },
  {
    path: '/verbs/:method_id/:lesson_from/:lesson_to/:coming_from',
    name: 'verbs',
    component: FormsPage
  },
  {
    path: '/nouns/:method_id/:lesson_from/:lesson_to/:coming_from',
    name: 'nouns',
    component: FormsPage
  },
  {
    path: '/learn/:method_id/:lesson_from/:lesson_to/:coming_from',
    name: 'learn',
    component: MeaningPage
  },
  {
    path: '/repeat/:method_id/:lesson_from/:lesson_to/:coming_from',
    name: 'repeat',
    component: MeaningPage
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
