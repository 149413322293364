<template>
  <nav id="navBar" class="navbar navbar-light bg-light d-flex justify-content-around">
        <div>
            <a href="#"><strong>Verbosa</strong></a>
        </div>
        <a id="navText" class="d-none d-md-inline">{{ nav_text }}</a>
        <router-link v-if="$route.params.coming_from == 'lesson'" :to="{name: 'lesson', params: {method_id: $route.params.method_id, lesson_id: $route.params.lesson_from}}"><button id="navButton" type="button" class="btn btn-secondary">Terug</button></router-link>
        <router-link v-if="$route.params.coming_from == 'select'" :to="{name: 'select'}"><button id="navButton" type="button" class="btn btn-secondary">Terug</button></router-link>
    </nav>
</template>

<script>
import axios from 'axios'
export default {
    name: 'NavComponent',
    props: {
        apiurl: String
    },
    data: function() {
        return {
            nav_text: '',
        }    
    },
    methods: {
        getName(id, type) {
            return new Promise(resolve => {
                const path = this.apiurl + '/' + type + '/' + id; 
                axios.get(path, {withCredentials: true})
                    .then((res) => {
                        if (type == 'methods') {
                            resolve(res.data.name);
                        }
                        if (type == 'lessons') {
                            resolve(res.data.lesson.name);
                        }
                    })
                    .catch((error) => {
                        this.$router.push({name: 'error', params: {error_code: -1}}); 
                        console.error(error);
                    });
            })
        },       
    },
    async created() {
        const method_name = await this.getName(this.$route.params.method_id, 'methods');
        const lesson_from_name = await this.getName(this.$route.params.lesson_from, 'lessons');
        const lesson_to_name = await this.getName(this.$route.params.lesson_to, 'lessons');
        const test_types = [{name: 'learn', dutch: 'leren'},
                            {name: 'repeat', dutch: 'herhalen'},
                            {name: 'nouns', dutch: 'naamvallen'},
                            {name: 'verbs', dutch: 'werkwoorden'},]
        for (const test_type of test_types) {
            if (this.$route.name == test_type.name) {
                if (this.$route.params.lesson_from == this.$route.params.lesson_to) {
                    this.nav_text = method_name + ' - les ' + lesson_from_name + ' - ' + test_type.dutch;
                }
                else {
                    this.nav_text = method_name + ' - les ' + lesson_from_name + ' t/m ' + lesson_to_name + ' - ' + test_type.dutch;
                }
            }
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
a {
    color: white !important;
    text-decoration: none !important;
    font-size: 1.2rem;
}

#navBar {
    background-color: #141BA6 !important;
    height: 80px !important;
    margin-bottom: 80px;
}

#navText{
    cursor: default;
}

#navButton {
    margin-left: 6% !important;
    background-color: #1ED994 !important;
    border: none !important;
    padding: 11px 18px !important;
}

button:active {
    outline: none;
}
</style>
