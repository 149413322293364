<template>
  <section class="col-11 col-md-9 col-lg-8 col-xl-5 mx-auto mb-5">
    <h5 class="mb-4">{{ instruction }}</h5>
    <div class="justify-content-center d-flex">
      <h1 v-if="$route.name == 'repeat'" class="word">{{ word.name }}{{ word.addition }}</h1>
      <h1 v-if="$route.name == 'learn'" class="word">{{ word.name }}{{ word.addition }}</h1>
      <h1 v-if="$route.name == 'nouns'" class="word">{{ word.name }}</h1>
      <h1 v-if="$route.name == 'verbs'" class="word">{{ word.name }}</h1>
      <div id="hint" class="align-items-center d-flex ps-3">
        <h1><PopoverComponent/><svg :data-popover="'komt van ' + word.word + word.addition" v-if="hint == false && $route.name == 'nouns' || $route.name == 'verbs'" xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-lightbulb my-auto" viewBox="0 0 16 16">
          <path :data-popover="'komt van ' + word.word + word.addition" d="M2 6a6 6 0 1 1 10.174 4.31c-.203.196-.359.4-.453.619l-.762 1.769A.5.5 0 0 1 10.5 13a.5.5 0 0 1 0 1 .5.5 0 0 1 0 1l-.224.447a1 1 0 0 1-.894.553H6.618a1 1 0 0 1-.894-.553L5.5 15a.5.5 0 0 1 0-1 .5.5 0 0 1 0-1 .5.5 0 0 1-.46-.302l-.761-1.77a1.964 1.964 0 0 0-.453-.618A5.984 5.984 0 0 1 2 6zm6-5a5 5 0 0 0-3.479 8.592c.263.254.514.564.676.941L5.83 12h4.342l.632-1.467c.162-.377.413-.687.676-.941A5 5 0 0 0 8 1z"/>
        </svg></h1>
      </div>
    </div>
  </section>
</template>

<script>
import PopoverComponent from './PopoverComponent.vue'
export default {
  name: 'WordComponent',
  components: {
    PopoverComponent
  },
  props: {
    instruction: String,
    word: [ Object, String ]
  },
  data: function() {
    return {
      hint: false
    }    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.word {
  color: black;
}
#hint {
  width: 0px;
  white-space: nowrap;
}
#hint-text {
  color: #909090;
}
svg {
  color: #909090;
}
svg:hover {
  cursor: pointer;
}
</style>
