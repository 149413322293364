<template>
  <div id="container" class="text-center py-5">
    <h2 id="form">{{ activities[current_activity].form }}</h2>
    <div v-if="checking" class="col-10 d-flex justify-content-between flex-wrap mx-auto mt-4">
      <div :class="activities[current_activity].style" v-for="(option, x) in activities[current_activity].options" :key="x">
        <div v-if="option !== ''">
          <div class="label-answer" :class="feedback[x]">{{ option }}</div>
        </div>
      </div>
      <input @click="next()" class="mt-3 col-6 mx-auto" type="submit" id="submitButton" value="Verder">
    </div>
    <div v-else class="col-10 d-flex justify-content-between flex-wrap mx-auto mt-4">
      <div :class="activities[current_activity].style" v-for="(option, x) in activities[current_activity].options" :key="x">
        <div v-if="option !== ''">
          <input type="checkbox" 
          :id="option" 
          v-model="checkedoptions" 
          :value="option" 
          hidden>
          <label class="label" :for="option">{{ option }}</label>
        </div>
      </div>
      <input @click="check()" class="mt-3 col-6 mx-auto" type="submit" id="submitButton" value="Check">
    </div>
  </div>
</template>

<script>
export default {
  name: 'PreviewComponent',
  data() {
    return {
      activities: [{form: 'servi', options: ['nom ev', 'nom mv', 'gen ev', 'gen mv', 'dat ev', 'dat mv', 'acc ev', 'acc mv', 'abl ev', 'abl mv'], style: 'twoColumns', correct_answers: ['gen ev', 'nom mv']},
                   {form: 'vocat', options: ['1e ev', 'ind', 'prae', '2e ev', 'con', 'imperf', '3e ev', '', 'fut', '1e mv', '', 'perf', '2e mv', 'act', 'pqperf', '3e mv', 'pas', 'fut ex'], style: 'threeColumns', correct_answers: ['3e ev', 'ind', 'prae', 'act']},
                   {form: 'τῷ ποτᾰμῷ', options: ['nom_ ev', 'nom mv', 'gen ev', 'gen mv', 'dat ev', 'dat mv', 'acc ev', 'acc mv'], style: 'twoColumns', correct_answers: ['dat ev']},
                   {form: 'λέγομεν', options: ['1e ev', 'ind', 'prae', '2e ev', 'con', 'imperf', '3e ev', 'opt', 'perf', '1e mv', 'act', 'aor', '2e mv', 'med', 'fut', '3e mv', 'pas', ''], style: 'threeColumns', correct_answers: ['1e mv', 'prae', 'act', 'ind']}],
      current_activity: 0,
      checking: false,
      feedback: [],
      form: 'servi',
      checkedoptions: []
    }
  },
  methods: {
    check() {
      for (const option of this.activities[this.current_activity].options) {
        if (this.activities[this.current_activity].correct_answers.includes(option)) {
          if (this.checkedoptions.includes(option)) {
            this.feedback.push('right');
          }
          else {
            this.feedback.push('missed');
          }
        } 
        else if (this.checkedoptions.includes(option)) {
          this.feedback.push('wrong');
        }
        else {
          this.feedback.push(''); 
        }
      }
      this.checking = true;
    },
    next() {
      if (this.current_activity < this.activities.length - 1) {
        this.current_activity ++;
      }
      else {
        this.current_activity = 0;
      }
      this.checking = false;
      this.checkedoptions = [];
      this.feedback = [];
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

#container {
  background-color: #f7f7f7 !important;
  border-radius: 15px;
  width: 100%;
  height: 100%;
}

#form {
  color: black;
}

label, .label-answer {
  margin: 12px 0 0 0;
  font-size: 1rem;
  padding: 8px 0;
  width: 100%;
  text-align: center;
  background-color: white;
  color: black;
  cursor: pointer !important;
  -webkit-box-shadow: -1px 1px 3px 1px rgba(0,0,0,0.07);
  -moz-box-shadow: -1px 1px 3px 1px rgba(0,0,0,0.07);
  box-shadow: -1px 1px 3px 1px rgba(0,0,0,0.07);
  border-radius: 10px;
}

.twoColumns {
  width: 48% !important;
}

.threeColumns {
  width: 31.5% !important;
}

label:hover {
  background-color: rgb(250,250,250);
}

input:checked ~ label{
  background-color: rgb(220, 220, 220);
}

input#submitButton {
    width: 50% !important;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: white !important;
    -webkit-box-shadow: -1px 1px 3px 1px rgba(0,0,0,0.07);
    -moz-box-shadow: -1px 1px 3px 1px rgba(0,0,0,0.07);
    box-shadow: -1px 1px 3px 1px rgba(0,0,0,0.07);
    border: none !important;
    border-radius: 10px !important;
}

.right {
  background-color: #1ED994;
}

.missed {
  background-color: #F4AA09;
}

.wrong {
  background-color: #EB5528;
}
</style>
