<template>
  <NavComponent :apiurl="apiurl"/>
  <div v-if="word" class="col-11 col-md-9 col-lg-8 col-xl-5 mx-auto my-5">
    <div class="w-100" id="progression-bar">
      <div id="progression" :style="progress_style">
      </div>
    </div>
  </div>
  <WordComponent v-if="test_method" :instruction="instruction" :word="word"/>
  <component :apiurl="apiurl" :is="component" :options="options" :correct="correct" :correctanswer="correct_answer" :answer="answer" :optionanswer="option_answer" :feedback="feedback" @checkMulti="checkMulti" @checkOpen="checkOpen" @revealOpen="revealOpen" @next="next()"/>
</template>

<script>
import axios from 'axios'
import { shallowRef } from 'vue'
import NavComponent from './sub/NavComponent.vue'
import WordComponent from './sub/WordComponent.vue'
import OptionsComponent from './sub/OptionsComponent.vue'
import OptionsShowComponent from './sub/OptionsShowComponent.vue'
import TextComponent from './sub/TextComponent.vue'
import TextShowComponent from './sub/TextShowComponent.vue'
import TextRevealComponent from './sub/TextRevealComponent.vue'
import FinishedComponent from './sub/FinishedComponent.vue'
export default {
  name: 'LearnPage',
  components: {
    NavComponent,
    WordComponent,
    OptionsComponent,
    OptionsShowComponent,
    TextComponent,
    TextShowComponent,
    TextRevealComponent,
    FinishedComponent
  },
  props: {
    apiurl: String
  },  
  data: function() {
    return {
      answer: '',
      option_answer: '',
      component: '',
      instruction: '',
      word: '',
      test_method: '',
      checked: false,
      options: [],
      feedback: [],
      correct: '',
      correct_answer: [],
      progress_style: '',
      finished: '',
      wordcount: '',
    }    
  },
  methods: {
    getData() {
      return new Promise(resolve => {
        var path = '';
        if (this.$route.name == 'repeat') {
          path = this.apiurl + '/lessons/' + this.$route.params.lesson_from + '/' + this.$route.params.lesson_to + '/repeat';
        }
        if (this.$route.name == 'learn') {
          path = this.apiurl + '/lessons/' + this.$route.params.lesson_from + '/learn';
        }
        axios.get(path, {withCredentials: true})
          .then((res) => {
            this.word = res.data.word;
            this.test_method = res.data.test_method;
            this.options = res.data.options;
            this.wordcount = res.data.wordcount;
            this.finished = res.data.finished;
            resolve('successful data reception')
          })
          .catch((error) => {
            this.$router.push({name: 'error', params: {error_code: -1}}); 
            console.error(error);
          });
      })
    },
    setComponent() {
      if (this.test_method == 'open') {
        this.component = shallowRef(TextComponent);
        this.instruction = 'Geef de juiste betekenis(sen) van het woord';
      }
      if (this.test_method == 'multi') {
        this.component = shallowRef(OptionsComponent);
        this.instruction = 'Geef de juiste betekenis(sen) van het woord'; 
      }
      if (this.test_method == 'show') {
        this.component = shallowRef(TextRevealComponent);
        this.instruction = 'Dit is/zijn de juiste betekenis(sen) van het woord';
        var path = '';
        if (this.$route.name == 'repeat') {
          path = this.apiurl + '/words/' + this.word.id + '/check_repeat_open'; 
        }
        if (this.$route.name == 'learn') {
          path = this.apiurl + '/words/' + this.word.id + '/check_learn_open'; 
        }
        axios.get(path, {withCredentials: true, params: {answer: ''}})
        .then((res) => {
          this.correct = res.data.correct;
          this.correct_answer = res.data.correct_answer;
        })
        .catch((error) => {
          this.$router.push({name: 'error', params: {error_code: -1}}); 
          console.error(error);
        });
      }
      if (this.test_method == null) {
        this.component = shallowRef(FinishedComponent);
      }
    },
    checkOpen(answer) {
      if (this.checked == false) {
        this.checked = true;
        this.answer = answer;
        var path = '';
        if (this.$route.name == 'repeat') {
          path = this.apiurl + '/words/' + this.word.id + '/check_repeat_open'; 
        }
        if (this.$route.name == 'learn') {
          path = this.apiurl + '/words/' + this.word.id + '/check_learn_open'; 
        }
        axios.get(path, {withCredentials: true, params: {answer: answer}})
          .then((res) => {
            this.component = shallowRef(TextShowComponent);
            this.correct = res.data.correct;
            this.correct_answer = res.data.correct_answer;
            if (this.correct) {
              this.instruction = 'Je hebt de juiste betekenis(sen) gegeven!';
            }
            else {
              this.instruction = 'Je hebt niet de juiste betekenis(sen) gegeven...';
            }
          })
          .catch((error) => {
            this.$router.push({name: 'error', params: {error_code: -1}}); 
            console.error(error);
          });
        }
    },
    retrieveMultiFeedback(option) {
      return new Promise(resolve => {
        var path = '';
        if (this.$route.name == 'repeat') {
          path = this.apiurl + '/words/' + this.word.id + '/check_repeat_multi'; 
        }
        if (this.$route.name == 'learn') {
          path = this.apiurl + '/words/' + this.word.id + '/check_learn_multi'; 
        }
        axios.get(path, {withCredentials: true, params: {answer: option}})
          .then((res) => {
            this.correct = res.data.correct;
            this.correct_answer = res.data.correct_answer;
            resolve('successful multi feedback reception')
          })
          .catch((error) => {
            this.$router.push({name: 'error', params: {error_code: -1}}); 
            console.error(error);
          });
      })
    },
    revealOpen() {
      this.instruction = 'Dit is/zijn de juiste betekenis(sen) van het woord';
      this.component = shallowRef(TextRevealComponent);
    },
    async checkMulti(option) {
      this.option_answer = option;
      await this.retrieveMultiFeedback(option);
      if (this.correct) {
        this.instruction = 'Je hebt de juiste betekenis(sen) gegeven';
      }
      else {
        this.instruction = 'Dit is/zijn de juiste betekenis(sen) van het woord';
      }
      this.component = shallowRef(OptionsShowComponent);
    },
    async next() {
      this.answer = '';
      this.correct = '';
      this.correct_answer = [];
      this.feedback = [];
      this.checked = false;
      await this.getData()
      this.setComponent()
      this.progress_style = 'width: ' + 100 * this.finished/this.wordcount + '%';
    }
  },
  async created() {
    await this.getData()
    this.setComponent()
    this.progress_style = 'width: ' + 100 * this.finished/this.wordcount + '%';
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#progression-bar {
  height: 17px;
  border-radius: 10px;
  border: 2px solid #141BA6;
}
#progression {
  height: 13px;
  border-radius: 10px;
  background-color: #141BA6;
}
</style>
