<template>
  <section class="col-11 col-md-9 col-lg-8 col-xl-5 mx-auto d-flex justify-content-between flex-wrap">
    <div class="twoColumns mb-4" v-for="(option, x) in options" :key="x">
      <div :class="feedback[x]" class="option position-relative"><p v-for="(meaning, y) in option" :key="y">{{ meaning }}</p>
        <p class="position-absolute key-indicator">{{ x+1 }}</p>
      </div>  
    </div>
    <input @click="$emit('next')" class="mt-4 col-6 mx-auto" type="submit" id="submitButton" value="Verder">
  </section>
</template>

<script>
export default {
  name: 'OptionsShowComponent',
  props: {
    options: Array,
    correct: Boolean,
    correctanswer: Array,
    optionanswer: Array
  },
  data: function() {
    return {
      feedback: []
    }    
  },
  methods: {
    setFeedback() {
      var feedback_array = [];
      for (const option of this.options) {
        var feedback = ''
        if (JSON.stringify(this.optionanswer) === JSON.stringify(option)) {
          if (this.correct) {
            feedback = 'right';
          }
          else {
            feedback = 'wrong';
          }
        }
        //for (var i = 0; i < this.optionanswer.length; i++) {
        //  if (this.optionanswer[i] == option[i]) {
        //    if (this.correct) {
        //      feedback = 'right';
        //    }
        //    else {
        //      feedback = 'wrong';
        //    }
        //  } 
        //}
        if (JSON.stringify(this.correctanswer) === JSON.stringify(option)) {
          if (this.correct) {
            feedback = 'right';
          }
          else {
            feedback = 'missed';
          }
        }
        //for (var j = 0; j < this.correctanswer.length; j++) {
        //  if (this.correctanswer[j] == option[j]) {
        //    if (this.correct) {
        //      feedback = 'right';
        //    }
        //    else {
        //      feedback = 'missed';
        //    }
        //  }  
        //}
        feedback_array.push(feedback)
      }
      this.feedback = feedback_array;
    }
  },
  created() {
    this.setFeedback();
    window.addEventListener("keypress", (event)=> {
      if (event.key === "Enter") {
        this.$emit('next');
      }
    });
  }
}
</script> 

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.option {
  word-wrap: break-word;
  height: 100%;
  padding: 12px 30px 0 30px;
  width: 100% !important;
  text-align: center;
  background-color: white;
  cursor: pointer !important;
  -webkit-box-shadow: -1px 1px 3px 1px rgba(0,0,0,0.07);
  -moz-box-shadow: -1px 1px 3px 1px rgba(0,0,0,0.07);
  box-shadow: -1px 1px 3px 1px rgba(0,0,0,0.07);
  border-radius: 15px;
}

.key-indicator {
  top: 13px;
  left: 15px;
}

.right {
  background-color: #1ED994 !important;
}

.missed {
  background-color: #F4AA09 !important;
}

.wrong {
  background-color: #EB5528 !important;
}

.twoColumns {
  width: 47% !important;
}

label:hover {
  background-color: rgb(250,250,250);
}

input:checked ~ label{
  background-color: rgb(220, 220, 220);
}

input#submitButton {
    width: 50% !important;
    padding-top: 25px;
    padding-bottom: 25px;
    background-color: white !important;
    -webkit-box-shadow: -1px 1px 3px 1px rgba(0,0,0,0.07);
    -moz-box-shadow: -1px 1px 3px 1px rgba(0,0,0,0.07);
    box-shadow: -1px 1px 3px 1px rgba(0,0,0,0.07);
    border: none !important;
    border-radius: 10px !important;
}
</style>
