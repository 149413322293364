<template>
  <NavComponent v-bind:navtext="nav_text" :apiurl="apiurl"/>
  <WordComponent :instruction="instruction" :word="form"/>
  <component :is="component" :width="width" :options="options" :right="right" :missed="missed" :wrong="wrong" @check="check" @next="next"/>
</template>

<script>
import axios from 'axios'
import { shallowRef } from 'vue'
import NavComponent from './sub/NavComponent.vue'
import WordComponent from './sub/WordComponent.vue'
import FormsComponent from './sub/FormsComponent.vue'
import FormsRevealComponent from './sub/FormsRevealComponent.vue'
export default {
  name: 'FormsPage',
  components: {
    NavComponent, 
    WordComponent,
    FormsComponent,
    FormsRevealComponent,
  },
  props: {
    apiurl: String
  },  
  data: function() {
    return {
      component: shallowRef(FormsComponent),
      nav_text: '',
      instruction: '',
      form: '',
      options: [],
      width: '',
      right: [],
      missed: [],
      wrong: []
    }    
  },
  methods: {
    getForm() {
      return new Promise(resolve => {
        const path = this.apiurl + '/methods/' + this.$route.params.method_id + '/lessons/' + this.$route.params.lesson_from + '/' + this.$route.params.lesson_to + '/' + this.$route.name + '/form'
        axios.get(path, {withCredentials: true})
          .then((res) => {
            this.form = res.data.form;
            resolve('Successful form request')
          })
          .catch((error) => {
            if (error.response.data.message == 'Wordtype not found') {
              var code = ''
              if (this.$route.name == 'nouns') {
                code = 0
              }
              else {
                code = 1
              }
              this.$router.push({name: 'error', params: {error_code: code}}); 
            }
            resolve('Unsuccessful form request')
          });
      })
    },
    getOptions() {
      const path = this.apiurl + '/methods/' + this.$route.params.method_id + '/' + this.$route.name + '/options';
      axios.get(path, {withCredentials: true})
        .then((res) => {
          this.options = res.data.form_options;
          if (res.data.columns == 2) {
            this.width = 'twoColumns';
          }
          if (res.data.columns == 3) {
            this.width = 'threeColumns';
          }
        })
        .catch((error) => {
          this.$router.push({name: 'error', params: {error_code: -1}}); 
          console.error(error);
        });
    },
    setContent() {
      if (this.$route.name == 'verbs') {
        this.nav_text = '"method" - les ' + this.$route.params.lesson_from + ' t/m ' + this.$route.params.lesson_to + ' - werkwoorden oefenen';
        this.instruction = 'Geef de juiste vervoeging van dit werkwoord'
      }
      if (this.$route.name == 'nouns') {
        this.nav_text = '"method" - les ' + this.$route.params.lesson_from + ' t/m ' + this.$route.params.lesson_to + ' - naamvallen oefenen';
        this.instruction = 'Geef de juiste naamval van deze vorm'
      }
    },
    check(checkedOptions) {
      var path = '';
      if (this.$route.name == 'nouns') {
        path = this.apiurl + '/check_noun'; 
      }
      if (this.$route.name == 'verbs') {
        path = this.apiurl + '/check_verb'; 
      }
      axios.get(path, {withCredentials: true, params: {answer: checkedOptions}})
        .then((res) => {
          this.right = res.data.right;
          this.missed = res.data.missed;
          this.wrong = res.data.wrong;
          this.component = shallowRef(FormsRevealComponent);
        })
        .catch((error) => {
          this.$router.push({name: 'error', params: {error_code: -1}}); 
          console.error(error);
        });
    },
    next(){
      this.component = shallowRef(FormsComponent);
      this.getForm();
      this.setContent();
    }
  },
  async created() {
    await this.getForm();
    this.getOptions();
    this.setContent();
  }
}
</script> 

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
