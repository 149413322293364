<template>
  <section class="d-lg-flex col-11 col-md-10 col-xl-8 mx-auto justify-content-center p-0 mt-5">
    <div id="container" class="mx-auto mx-lg-0 d-flex flex-column justify-content-between col-11 col-lg-6 p-5">
      <h4 class="mb-4">{{ text }}</h4>
      <p class="mb-5">Gebruik de knoppen om verder te gaan</p>
      <div id="progress" class="w-100">
      </div>
    </div>
    <div id="buttons" class="mx-auto mx-lg-0 col-11 col-lg-3 p-0 px-lg-5 d-flex d-lg-block align-items-center mt-4 my-lg-auto">
      <div class="w-100 d-flex flex-column flex-md-row flex-lg-column justify-content-center justify-content-md-between justify-content-lg-center">
        <router-link @click="$route.params.lesson_from = next_lesson_id, $route.params.lesson_to = next_lesson_id, $emit('next')" :to="{name: 'learn', params: {method_id: $route.params.method_id, lesson_from: next_lesson_id, lesson_to: next_lesson_id, coming_from: 'lesson'}}">
          <button v-if="$route.name == 'learn'" class="btn lesson-button my-2 text-center text-lg-left mx-auto mx-md-0">
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-lightbulb me-2" viewBox="0 0 16 16">
              <path d="M2 6a6 6 0 1 1 10.174 4.31c-.203.196-.359.4-.453.619l-.762 1.769A.5.5 0 0 1 10.5 13a.5.5 0 0 1 0 1 .5.5 0 0 1 0 1l-.224.447a1 1 0 0 1-.894.553H6.618a1 1 0 0 1-.894-.553L5.5 15a.5.5 0 0 1 0-1 .5.5 0 0 1 0-1 .5.5 0 0 1-.46-.302l-.761-1.77a1.964 1.964 0 0 0-.453-.618A5.984 5.984 0 0 1 2 6zm6-5a5 5 0 0 0-3.479 8.592c.263.254.514.564.676.941L5.83 12h4.342l.632-1.467c.162-.377.413-.687.676-.941A5 5 0 0 0 8 1z"/>
            </svg>
            Verder leren
          </button>
        </router-link>
        <router-link :to="{name: 'repeat', params: {method_id: $route.params.method_id, lesson_from: $route.params.lesson_from, lesson_to: $route.params.lesson_from, coming_from: 'lesson'}}">
          <button @click="clearRepeat()" v-if="$route.name == 'learn'" class="btn lesson-button my-2 text-center text-lg-left mx-auto mx-md-0">
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-arrow-repeat me-2" viewBox="0 0 16 16">
              <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z"/>
              <path fill-rule="evenodd" d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"/>
            </svg>
            Herhalen
          </button>
        </router-link>
        <button @click="clearRepeat()" v-if="$route.name == 'repeat'" class="btn lesson-button my-2 text-center text-lg-left mx-auto mx-md-0">
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-arrow-repeat me-2" viewBox="0 0 16 16">
            <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z"/>
            <path fill-rule="evenodd" d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"/>
          </svg>
          Opnieuw
        </button>
        <router-link v-if="$route.params.coming_from == 'lesson'" :to="{name:'lesson', params: {method_id: $route.params.method_id, lesson_id: $route.params.lesson_from}}">
          <button class="btn lesson-button my-2 text-center text-lg-left mx-auto mx-md-0">
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-arrow-return-left me-2" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5z"/>
            </svg>
            Terug
          </button>      
        </router-link>
        <router-link v-else :to="{name: 'select'}">
          <button class="btn lesson-button my-2 text-center text-lg-left mx-auto mx-md-0">
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-arrow-return-left me-2" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5z"/>
            </svg>
            Terug
          </button>      
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios'
export default {
  name: 'FinishedComponent',
  props: {
    apiurl: String
  },
  data: function() {
    return {
      text: '',
      next_lesson_id: 0
    }
  },
  methods: {
    getName(id, type) {
      return new Promise(resolve => {
        const path = this.apiurl + '/' + type + '/' + id; 
        axios.get(path, {withCredentials: true})
          .then((res) => {
            if (type == 'methods') {
                resolve(res.data.name);
            }
            if (type == 'lessons') {
                resolve(res.data.lesson.name);
            }
          })
          .catch((error) => {
            this.$router.push({name: 'error', params: {error_code: -1}}); 
            console.error(error);
          });
      })
    },
    getNextLessonId(id) {
      const path = this.apiurl + '/lessons/' + id; 
        axios.get(path, {withCredentials: true})
          .then((res) => {
            this.next_lesson_id = res.data.lesson.next;
          })
          .catch((error) => {
           this.$router.push({name: 'error', params: {error_code: -1}}); 
            console.error(error);
          });
    },
    clearRepeat() {
      const path = this.apiurl + '/clear_repeat';
      axios.get(path, {withCredentials: true})
        .then(
          setTimeout(() => {
            this.$emit('next');
          }, 200)
        )
        .catch((error) => {
          this.$router.push({name: 'error', params: {error_code: -1}}); 
          console.error(error);
        });
    }
  },
  async created() {
    this.getNextLessonId(this.$route.params.lesson_from);
    const lesson_from_name = await this.getName(this.$route.params.lesson_from, 'lessons');
    const lesson_to_name = await this.getName(this.$route.params.lesson_to, 'lessons');
    if (this.$route.name == 'repeat') {
      if (this.$route.params.lesson_from == this.$route.params.lesson_to) {
        this.text = 'Je hebt les ' + lesson_from_name + ' herhaald!';
      }
      else {
        this.text = 'Je hebt les ' + lesson_from_name + ' t/m ' + lesson_to_name + ' herhaald!';
      }
    }
    if (this.$route.name == 'learn') {
      this.text = 'Je hebt les ' + lesson_from_name + ' geleerd!';
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
a:hover {
  color: black;
}
#container {
  background-color: white;
  border-radius: 8px;
  box-shadow: -1px 1px 3px 1px rgb(0 0 0 / 4%);
}

#progress {
  background-color: #141BA6;
  border-radius: 10px;
  height: 16px;
}
button {
  width: 200px;
}

button:hover {
  background-color: rgba(0, 0, 0, 0.2);
  border-color: transparent;
}
</style>
