<template>
  <form v-if="correct === true" class="col-11 col-md-9 col-lg-8 col-xl-5 mx-auto mt-5 d-flex justify-content-between">
    <input class="textInput right" type="text" :value="answer" disabled> 
    <input @click="$emit('next')" class="submitButton text-center" id="correct-submit" value="verder">
    <!--<input class="questionButton text-center" type="submit" value="?">-->
  </form>
  <form v-else-if="correct !== ''" class="col-11 col-md-9 col-lg-8 col-xl-5 mx-auto mt-5 d-flex justify-content-between">
    <input class="textInput wrong" type="text" :value="answer" disabled> 
    <input @click="$emit('revealOpen')" class="submitButton text-center" id="wrong-submit" value="tonen">
    <!--<input class="questionButton text-center" type="submit" value="?">-->
  </form>
</template>

<script>
export default {
  name: 'TextShowComponent',
  props: {
    correct: Boolean,
    answer: String
  },
  created() {
    window.addEventListener("keypress", (event)=> {
      if (event.key === "Enter") {
        if (this.correct) {
          this.$emit('next');
        }
        else {
          this.$emit('revealOpen');
        }
      }
    });
  }
}
</script> 

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
input {
  color: black;
  font-size: 1.4rem;
  background-color: white;
  -webkit-box-shadow: -1px 1px 3px 1px rgba(0,0,0,0.07);
  -moz-box-shadow: -1px 1px 3px 1px rgba(0,0,0,0.07);
  box-shadow: -1px 1px 3px 1px rgba(0,0,0,0.07);
  height: 75px;
  border-radius: 15px;
  border: none !important;
}

.textInput {
  padding-left: 30px;
  width: 77%;
}

.right {
  background-color: #1ED994;
}

.wrong {
  background-color: #EB5528;
}

.submitButton {
  width: 20%;
}

/*.questionButton {
  width: 8%;
}*/

input:focus {
  outline: none !important;
}

.questionButton:hover, .submitButton:hover {
  cursor: pointer;
}

ol {
  width: 74%;
}

li {
  font-size: 1.4rem;
}
</style>
