<template>
  <router-view :apiurl="apiurl"/>
</template>

<script>
import axios from 'axios'
import Config from "./config.json";
export default {
  name: 'App',
  data: () => {
    return {
      env: Config.ENV,
      apiurl: Config.API_URL
    }
  },
  methods: {
    checkLogin() {
      return new Promise(resolve => {
        const path = this.apiurl + '/check_login';
        axios.get(path, {withCredentials: true})
          .then((res) => {
            resolve(res.data.login); 
          })
          .catch((error) => {
            console.error(error);
          });
      })
    },
  },
  async created() {
    const logged_in = await this.checkLogin();
    if (this.$route.name == 'home') {
      if (logged_in) {
        this.$router.push({name: 'select'});
      }
    }
    else {
      if (logged_in == false) {
        this.$router.push({name: 'home'});
      }
    } 
  }
}
</script>

<style>
#app {
  font-family: Tahoma, Geneva, sans-serif;
}

html, body {
  background-color: #f7f7f7 !important;
  scroll-behavior: smooth;
}
</style>
