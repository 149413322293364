<template>
  <section class="col-11 col-md-9 col-lg-8 col-xl-5 mx-auto d-flex justify-content-between flex-wrap">
    <div :class="width" class="mb-2" v-for="(option, x) in options" :key="x">
      <div v-if="option.name !== ''">
        <input type="checkbox" 
        :id="option.name" 
        :value="option.name" 
        hidden disabled>
        <div v-if="right.includes(option.name)">
          <label :for="option.name" class="d-none d-md-inline-block right" disabled>{{ option.full }}</label> 
          <label :for="option.name" class="d-md-none right" disabled>{{ option.short }}</label>
        </div>
        <div v-else-if="missed.includes(option.name)">
          <label :for="option.name" class="d-none d-md-inline-block missed" disabled>{{ option.full }}</label> 
          <label :for="option.name" class="d-md-none missed" disabled>{{ option.short }}</label>
        </div>
        <div  v-else-if="wrong.includes(option.name)">
          <label :for="option.name" class="d-none d-md-inline-block wrong" disabled>{{ option.full }}</label> 
          <label :for="option.name" class="d-md-none wrong" disabled>{{ option.short }}</label>
        </div>
        <div v-else>
          <label :for="option.name" class="d-none d-md-inline-block" disabled>{{ option.full }}</label> 
          <label :for="option.name" class="d-md-none" disabled>{{ option.short }}</label>
        </div>
      </div>
    </div>
    <input @click="$emit('next')" class="mt-4 col-6 mx-auto" type="submit" id="submitButton" value="Verder">
  </section>
</template>

<script>
export default {
  name: 'FormsRevealComponent',
  props: {
    options: Array,
    width: String,
    right: Array,
    missed: Array,
    wrong: Array
  },
  created() {
    window.addEventListener("keypress", (event)=> {
      if (event.key === "Enter") {
        this.$emit('next')
      }
    });
  }
}
</script> 

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
label {
  padding: 12px 0;
  width: 100%;
  text-align: center;
  background-color: white;
  -webkit-box-shadow: -1px 1px 3px 1px rgba(0,0,0,0.07);
  -moz-box-shadow: -1px 1px 3px 1px rgba(0,0,0,0.07);
  box-shadow: -1px 1px 3px 1px rgba(0,0,0,0.07);
  border-radius: 10px;
}

.twoColumns {
  width: 48% !important;
}

.threeColumns {
  width: 31.5% !important;
}

input#submitButton {
    width: 50% !important;
    padding-top: 25px;
    padding-bottom: 25px;
    background-color: white !important;
    -webkit-box-shadow: -1px 1px 3px 1px rgba(0,0,0,0.07);
    -moz-box-shadow: -1px 1px 3px 1px rgba(0,0,0,0.07);
    box-shadow: -1px 1px 3px 1px rgba(0,0,0,0.07);
    border: none !important;
    border-radius: 10px !important;
}

.right {
  background-color: #1ED994;
}

.missed {
  background-color: #F4AA09;
}

.wrong {
  background-color: #EB5528;
}
</style>
