<template>
  <section class="col-11 col-md-9 col-lg-8 col-xl-5 mx-auto d-flex justify-content-between flex-wrap">
    <div class="twoColumns mb-4" v-for="(option, x) in options" :key="x">
      <div @click="$emit('checkMulti', option)" class="option position-relative"><p v-for="(meaning, y) in option" :key="y">{{ meaning }}</p>
        <p class="position-absolute key-indicator">{{ x+1 }}</p>
      </div> 
    </div>
  </section>
</template>

<script>
export default {
  name: 'OptionsComponent',
  props: {
    options: Array,
  },
  created() {
    window.addEventListener("keypress", (event)=> {
      let number_keys = ["1", "2", "3", "4", "5", "6"];
      for (const number_key of number_keys) {
        if(event.key === number_key) {
          this.$emit('checkMulti', this.options[parseInt(number_key)-1])
        }
      }
    });
  }
}
</script> 

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.option {
  word-wrap: break-word;
  height: 100%;
  padding: 12px 30px 0 30px;
  width: 100% !important;
  text-align: center;
  background-color: white;
  cursor: pointer !important;
  -webkit-box-shadow: -1px 1px 3px 1px rgba(0,0,0,0.07);
  -moz-box-shadow: -1px 1px 3px 1px rgba(0,0,0,0.07);
  box-shadow: -1px 1px 3px 1px rgba(0,0,0,0.07);
  border-radius: 15px;
}

.key-indicator {
  top: 13px;
  left: 15px;
}

.twoColumns {
  width: 47% !important;
}

label:hover {
  background-color: rgb(250,250,250);
}

input:checked ~ label{
  background-color: rgb(220, 220, 220);
}

input#submitButton {
    width: 50% !important;
    padding-top: 25px;
    padding-bottom: 25px;
    background-color: white !important;
    -webkit-box-shadow: -1px 1px 3px 1px rgba(0,0,0,0.07);
    -moz-box-shadow: -1px 1px 3px 1px rgba(0,0,0,0.07);
    box-shadow: -1px 1px 3px 1px rgba(0,0,0,0.07);
    border: none !important;
    border-radius: 10px !important;
}
</style>
