<template>
    <footer class="site-footer pb-4">
        <div class="container">
            <div class="row">
                <div class="col-sm-12 col-md-6 text-center text-md-start mb-5 mb-md-0">
                    <h1>Verbosa</h1>
                </div>
                <div class="col-6 col-md-3">
                    <ul class="footer-links">
                    <li><a href="mailto:support@verbosa.nl">Contact</a></li>
                    <li><a href="">Aanmelden</a></li>
                    <li><a href="">Reviews</a></li>
                    <li><a href="">Docenten</a></li>
                    <li><a href="">Methodes</a></li>
                    </ul>
                </div>
                <div class="col-6 col-md-3">
                    <ul class="footer-links">
                    <li><a href="">Over ons</a></li>
                    <li><a href="">Verbosa</a></li>
                    <li><a href="">Privacy</a></li>
                    <li><a href="">Cookies</a></li>
                    <li><a href="">Donaties</a></li>
                    </ul>
                </div>
            </div>
            <hr>
        </div>
        <div class="container">
            <div class="row">
            <div class="col-md-8 col-sm-6 col-xs-12">
                <p class="copyright-text">Copyright &copy; 2022 Verbosa
                </p>
            </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
  name: 'ThFooter',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
footer {
color: #F2F2F2 !important;
background-color: #2D30B1;
padding-top: 80px;
}

footer a {
color: #F2F2F2 !important;
text-shadow: none !important;
}

footer li {
list-style-type: none;
}

footer img {
width: 140px !important;
}

footer hr {
border: 1px solid rgba(0,0,0,0.4);
}
</style>
