<template>
  <nav class="navbar navbar-expand-md navbar-light bg-light justify-content-between" id="custom-nav">
    <div class="d-flex">
      <a class="navbar-brand" href="#"><strong>Verbosa</strong></a>
    </div>
    <div class="dropdown">
      <router-link to="/select"><button class="btn btn-secondary " id="quitButton">Terug</button></router-link>
    </div>
  </nav>
  <section class="my-5 text-center">
    <h1 class="mb-3">Er is iets misgegaan...</h1>
    <p>{{ error_text }}</p>
  </section>
</template>

<script>
export default {
  name: 'FormsPage',
  data: function() {
    return {
      codes: ['Er zijn geen zelfstandige naamwoorden gevonden in deze les(sen)',
              'Er zijn geen werkwoorden gevonden in deze les(sen)'],
      error_text: ''
    }    
  },
  created() {
    if (this.$route.params.error_code) {
      this.error_text = this.codes[parseInt(this.$route.params.error_code)];
    }
  }
}
</script> 

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
nav {
    margin: 0;
}
  
nav a {
  color: white !important;
}

#custom-nav {
    background-color: #141BA6 !important;
    width:100%;
    height: 80px;
    padding: 0 11%;
}
  
#navbarCollapse #navLi:hover {
    text-decoration: underline;
}

#quitButton {
    color: white !important;
    background-color: #1ED994 !important;
    padding: 11px 18px;
    border-radius: 6px;
    border: none !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
}
</style>
