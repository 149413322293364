<template>
  <div v-if="data_complete" id="wrapper" ref="wrapper">
    <nav class="navbar navbar-expand-md navbar-light bg-light justify-content-between" id="custom-nav">
      <div class="d-flex">
        <a class="navbar-brand" href="index.html" id="test"><strong>Verbosa</strong></a>
      </div>
      <div class="dropdown">
        <button class="btn btn-secondary" @click="getUserData()" type="button" id="profile" data-bs-toggle="modal" ref="test" data-bs-target="#profileModal">
          Account
        </button>
      </div>
    </nav>
    <div class="modal fade" id="profileModal" tabindex="-1" aria-labelledby="profileModelLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Account</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" ref="closeButtonSelect"></button>
          </div>
          <div class="modal-body d-flex">
            <div id="profile-links" class="pe-4 pe-lg-5">
              <ul>
                <li v-if="displayed_profile_option == 'profile'" class="mb-3" @click="displayed_profile_option = 'profile'"><b>Profiel</b></li>
                <li v-else class="mb-3" @click="displayed_profile_option = 'profile'">Profiel</li>
                <li v-if="displayed_profile_option == 'settings'" class="mb-3" @click="displayed_profile_option = 'settings'"><b>Settings</b></li>
                <li v-else class="mb-3" @click="displayed_profile_option = 'settings'">Settings</li>
                <router-link to="/"><li class="mb-3" @click="logout()">Uitloggen</li></router-link>
              </ul>
            </div>
            <div v-if="displayed_profile_option == 'profile'" class="ps-4">
              <p>{{ user_email }}</p>
              <p>{{ user_school }}</p>
            </div>
            <div v-if="displayed_profile_option == 'settings'" class="ps-4 w-100">
              <h5 class="mb-3">Grammatica niveau</h5>
              <div class="mb-4" v-for="(method, x) in methods" :key="x">
                <h5>{{ method.language }}</h5>
                <div>
                  <input class="my-auto me-3" type="checkbox" v-model="method.auto" @change="method.grammar_lesson = method.grammar_lesson_auto, grammar_change = true" :id="method.language" name="drone" value="auto-grammar" :checked="method.auto">
                  <label :for="method.language">automatisch</label>
                </div>
                <div class="d-flex align-middle">
                  <input v-if="method.auto" type="range" :min="0" :max="method.lessons.length - 1" :value="method.grammar_lesson" class="slider col-8 col-lg-5" id="grammar-level" disabled>
                  <input v-else v-model="method.grammar_lesson" type="range" @change="grammar_change = true" :min="0" :max="method.lessons.length - 1" class="slider col-8 col-lg-5" id="grammar-level">
                </div>
                <p>Grammatica t/m les {{ method.lessons[method.grammar_lesson].name }}</p>
              </div>
              <button v-if="grammar_change" class="btn btn-secondary save" @click="grammar_change = false, updateGrammar()">Opslaan</button>
              <button v-else class="btn btn-secondary save save-disabled" disabled>Opslaan</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section id="method-nav" class="col-11 col-md-10 col-xl-8 mx-auto mb-5">
      <div id="method-links" class="d-flex justify-content-center">
        <a v-for="(method, x) in methods" :key="x" class="mx-5" @click="changeMethod(method.id)">{{ method.language }}</a>
      </div>
    </section>
    <section id="method" class="w-100 d-flex justify-content-center">
      <div id="method-container" class="col-8 col-lg-8 col-xl-6">
        <div class="d-flex align-items-center w-100 mt-4">
          <div class="d-none d-sm-block" id="methodLogo">
            <img id="method_logo" v-bind:src="require('../assets/'+displayed_method.simple_name+'.png')" alt="logo"/>
          </div>
          <div class="ms-md-5 ms-3 text-center text-sm-start">
            <h1>{{ displayed_method.name }}</h1>
            <p>Navigeer je hier door alle woorden en stamtijden van {{ displayed_method.name }} om verder te leren.</p>
          </div>
        </div>
      </div>
    </section>
    <section class="d-flex col-11 col-md-10 col-xl-8 mx-auto justify-content-center my-5 px-2 py-0">
      <div class="col-11 col-lg-3 d-none d-lg-block buttons-container p-0">
        <div class="d-flex h-100 align-items-center p-0">
          <div>
            <router-link :to="{name: 'nouns', params: {method_id: displayed_method.id, lesson_from: displayed_method.lessons[Math.round(barMinValue)].id, lesson_to: displayed_method.lessons[Math.round(barMaxValue)].id, coming_from: 'select'}}">
              <button class="btn lessons-button my-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-lightbulb me-2" viewBox="0 0 16 16">
                  <path d="M2 6a6 6 0 1 1 10.174 4.31c-.203.196-.359.4-.453.619l-.762 1.769A.5.5 0 0 1 10.5 13a.5.5 0 0 1 0 1 .5.5 0 0 1 0 1l-.224.447a1 1 0 0 1-.894.553H6.618a1 1 0 0 1-.894-.553L5.5 15a.5.5 0 0 1 0-1 .5.5 0 0 1 0-1 .5.5 0 0 1-.46-.302l-.761-1.77a1.964 1.964 0 0 0-.453-.618A5.984 5.984 0 0 1 2 6zm6-5a5 5 0 0 0-3.479 8.592c.263.254.514.564.676.941L5.83 12h4.342l.632-1.467c.162-.377.413-.687.676-.941A5 5 0 0 0 8 1z"/>
                </svg>
                Naamvallen
              </button>
            </router-link>
            <router-link :to="{name: 'verbs', params: {method_id: displayed_method.id, lesson_from: displayed_method.lessons[Math.round(barMinValue)].id, lesson_to: displayed_method.lessons[Math.round(barMaxValue)].id, coming_from: 'select'}}">
              <button class="btn lessons-button my-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-stoplights me-2" viewBox="0 0 16 16">
                  <path d="M8 5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zm0 4a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zm1.5 2.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                  <path d="M4 2a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2h2c-.167.5-.8 1.6-2 2v2h2c-.167.5-.8 1.6-2 2v2h2c-.167.5-.8 1.6-2 2v1a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-1c-1.2-.4-1.833-1.5-2-2h2V8c-1.2-.4-1.833-1.5-2-2h2V4c-1.2-.4-1.833-1.5-2-2h2zm2-1a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H6z"/>
                </svg>
                Werkwoorden
              </button>
            </router-link>
            <button @click="clearRepeat()" class="btn lessons-button my-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-arrow-repeat me-2" viewBox="0 0 16 16">
                <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z"/>
                <path fill-rule="evenodd" d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"/>
              </svg>
              Herhalen
            </button>
          </div>
        </div>
      </div>
      <div id="multiple-lessons" class="col-10 col-md-12 col-lg-6 text-center p-5">
        <h5>Meerdere Lessen Selecteren</h5>
        <div class="d-flex mx-auto mt-4 justify-content-center">
          <h4 class="col-1">{{ displayed_method.lessons[Math.round(barMinValue)].name }}</h4>
          <div id="range-slider" class="col-8 mt-2 me-4 ms-3">    
            <MultiRangeSlider
              :min="0"
              :max="displayed_method.lessons.length - 1"
              :step="0.1"
              :ruler="false"
              :label="false"
              :minValue="barMinValue"
              :maxValue="barMaxValue"
              @input="UpdateValues"
            />
          </div>
          <h4 class="col-1">{{ displayed_method.lessons[Math.round(barMaxValue)].name }}</h4>
        </div>
        <div class="d-md-flex">
          <router-link class="col-8 col-md-4 p-0" :to="{name: 'nouns', params: {method_id: displayed_method.id, lesson_from: displayed_method.lessons[Math.round(barMinValue)].id, lesson_to: displayed_method.lessons[Math.round(barMaxValue)].id, coming_from: 'select'}}">
            <button class="btn lessons-button-inside d-lg-none col-10 col-sm-8 col-md-11 my-1 my-md-0">
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-lightbulb me-2" viewBox="0 0 16 16">
                <path d="M2 6a6 6 0 1 1 10.174 4.31c-.203.196-.359.4-.453.619l-.762 1.769A.5.5 0 0 1 10.5 13a.5.5 0 0 1 0 1 .5.5 0 0 1 0 1l-.224.447a1 1 0 0 1-.894.553H6.618a1 1 0 0 1-.894-.553L5.5 15a.5.5 0 0 1 0-1 .5.5 0 0 1 0-1 .5.5 0 0 1-.46-.302l-.761-1.77a1.964 1.964 0 0 0-.453-.618A5.984 5.984 0 0 1 2 6zm6-5a5 5 0 0 0-3.479 8.592c.263.254.514.564.676.941L5.83 12h4.342l.632-1.467c.162-.377.413-.687.676-.941A5 5 0 0 0 8 1z"/>
              </svg>
              Naamvallen
            </button>
          </router-link>
          <router-link :to="{name: 'verbs', params: {method_id: displayed_method.id, lesson_from: displayed_method.lessons[Math.round(barMinValue)].id, lesson_to: displayed_method.lessons[Math.round(barMaxValue)].id, coming_from: 'select'}}" class="col-8 col-md-4 p-0">
            <button class="btn lessons-button-inside d-lg-none col-10 col-sm-8 col-md-11 my-1 my-md-0">
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-stoplights me-2" viewBox="0 0 16 16">
                <path d="M8 5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zm0 4a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zm1.5 2.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                <path d="M4 2a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2h2c-.167.5-.8 1.6-2 2v2h2c-.167.5-.8 1.6-2 2v2h2c-.167.5-.8 1.6-2 2v1a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-1c-1.2-.4-1.833-1.5-2-2h2V8c-1.2-.4-1.833-1.5-2-2h2V4c-1.2-.4-1.833-1.5-2-2h2zm2-1a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H6z"/>
              </svg>
              Werkwoorden
            </button>
          </router-link>
          <router-link class="col-8 col-md-4 p-0" :to="{name: 'repeat', params: {method_id: displayed_method.id, lesson_from: displayed_method.lessons[Math.round(barMinValue)].id, lesson_to: displayed_method.lessons[Math.round(barMaxValue)].id, coming_from: 'select'}}">
            <button @click="clearRepeat()" class="btn lessons-button-inside d-lg-none col-10 col-sm-8 col-md-11 my-1 my-md-0">
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-arrow-repeat me-2" viewBox="0 0 16 16">
                <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z"/>
                <path fill-rule="evenodd" d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"/>
              </svg>
              Herhalen
            </button>
          </router-link>
        </div>
      </div>
    </section>
    <section id="lessons" class="d-flex flex-wrap justify-content-center justify-content-sm-start col-11 col-md-10 col-xl-8 mx-auto p-0">
      <div v-for="(lesson, x) in displayed_method.lessons" :key="x" class="col-10 col-sm-6 col-md-4 col-lg-4 col-xl-3 lesson-container">
        <router-link :to="{name: 'lesson', params: {method_id: displayed_method.id, lesson_id: lesson.id}}">
          <div class="lesson">
            <div class="w-100 d-flex flex-column justify-content-between pt-4 px-4 pb-2 lesson-top">
              <div class="d-flex justify-content-between p-0">
                <p class="mb-0">{{ lesson.name }}</p>
                <p class="mb-0">{{ lesson.wordcount }} termen</p>
              </div>
              <h5>{{ lesson.title }}</h5>
            </div>
            <div v-if="lesson.learned" class="lesson-bottom learned">
            </div>
            <div v-else class="lesson-bottom not-learned">
            </div>
          </div>
        </router-link>
      </div>
    </section>
    <FooterComponent/>
  </div>
</template>

<script>
import axios from 'axios'
import MultiRangeSlider from "./sub/MultiRangeSlider";
import FooterComponent from './sub/FooterComponent.vue'
export default {
  name: 'SelectPage',
  components: {
    MultiRangeSlider,
    FooterComponent
  },
  props: {
    apiurl: String
  },
  data: function() {
    return {
      data_complete: false,
      methods: [],
      displayed_method: '',
      user_email: '',
      user_school: '',
      barMinValue: 0,
      barMaxValue: 0,
      displayed_profile_option: "profile",
      grammar_change: false,
    }    
  },
  methods: {
    logout() {
      this.$refs.closeButtonSelect.click()
      document.getElementsByClassName('modal-backdrop')[0].remove();
      this.$router.push({name: 'home'}); 
      const path = this.apiurl + '/logout';
      axios.get(path, {withCredentials: true})
        .catch((error) => {
          this.$router.push({name: 'error', params: {error_code: -1}}); 
          console.error(error);
        });
    },
    getMethods() { // returns all the methods of the current user
      return new Promise(resolve => {
        const path = this.apiurl + '/methods';
        //fetch(path, {method: 'GET', credentials: 'include', mode: 'no-cors'})
        axios.get(path, {withCredentials: true})
          .then((res) => {
            resolve(res.data.methods)
          })
          .catch((error) => {
            this.$router.push({name: 'error', params: {error_code: -1}}); 
            console.error(error);
          });
      })
    },
    getLessons(method_id) { // returns all the lessons for the method
      return new Promise(resolve => {
        const path = this.apiurl + '/methods/' + method_id + '/lessons';
        //fetch(path, {method: 'GET', credentials: 'include', mode: 'no-cors'})
        axios.get(path, {withCredentials: true})
          .then((res) => {
            resolve(res.data.lessons)
          })
          .catch((error) => {
            this.$router.push({name: 'error', params: {error_code: -1}}); 
            console.error(error);
          });
      })
    },
    setDisplayedMethod() { // sets the method that should be displayed on the screen
      return new Promise(resolve => {
        for (const method of this.methods) {
          if (method.last_used) {
            resolve(method)
          }
        }
      })
    },
    changeMethod(method_id) { // changes the method that is displayed on the screen
      if (method_id != this.displayed_method.id) {
        // put the method id that should be set as last_method for the current user
        axios.put(this.apiurl + '/methods/' + method_id + '/last', {}, {withCredentials: true});
      }
      for (const method of this.methods) {
        if (method.id == method_id) {
          this.displayed_method = method;
          this.setSliderValues();
        }
      }
    },
    getUserData() {
      const path = this.apiurl + '/users/current';
      axios.get(path, {withCredentials: true})
        .then((res) => {
          this.user_email = res.data.email;
          this.user_school = res.data.school;
        })
        .catch((error) => {
          this.$router.push({name: 'error', params: {error_code: -1}});  
          console.error(error);
        });
      for (const method of this.methods) {
        const path = this.apiurl + '/methods/' + method.id + '/level';
        axios.get(path, {withCredentials: true})
          .then((res) => {
            method.auto = res.data.auto;
            for (let i = 0; i < method.lessons.length; i++) {
              if (method.lessons[i].id == res.data.lesson_id) {
                method.grammar_lesson = i;
              }
              if (method.lessons[i].id == res.data.lesson_auto_id) {
                method.grammar_lesson_auto = i;
              }
            }
          })
          .catch((error) => {
            this.$router.push({name: 'error', params: {error_code: -1}}); 
            console.error(error);
          });
      }
    },
    updateGrammar() {
      for (const method of this.methods) {
        const path = this.apiurl + '/methods/' + method.id + '/level';
        axios.put(path, {auto: method.auto, lesson_id: method.lessons[method.grammar_lesson].id}, {withCredentials: true});
      }
    },
    clearRepeat() {
      const path = this.apiurl + '/clear_repeat';
      axios.get(path, {withCredentials: true})
        .then(
          setTimeout(() => {
            this.$router.push({name: 'repeat', params: {method_id: this.displayed_method.id, lesson_from: this.displayed_method.lessons[Math.round(this.barMinValue)].id, lesson_to: this.displayed_method.lessons[Math.round(this.barMaxValue)].id, coming_from: 'select'}})
          }, 200)
        )
        .catch((error) => {
          this.$router.push({name: 'error', params: {error_code: -1}}); 
          console.error(error);
        });
    },
    setSliderValues() {
      this.barMinValue = 0;
      this.barMaxValue = this.displayed_method.lessons.length - 1;
    },
    UpdateValues(e) {
      this.barMinValue = e.minValue;
      this.barMaxValue = e.maxValue;
    }
  },
  async created() {
    this.methods = await this.getMethods()
    for (var i = 0; i < this.methods.length; i++) {
      this.methods[i]['lessons'] = await this.getLessons(this.methods[i].id)
    }
    this.displayed_method = await this.setDisplayedMethod();
    this.setSliderValues();
    this.data_complete = true;
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
a:hover {
  color: black;
}

#range-slider {
  height: 30px !important;
}

#multiple-lessons {
  background-color: white;
  border-radius: 8px;
  box-shadow: -1px 1px 3px 1px rgb(0 0 0 / 4%);
}

.lesson-container {
  padding: 10px;
}

.lessons-button {
  width: 200px;
  text-align: left;
}

.lessons-button:hover, .lessons-button-inside:hover {
  background-color: rgba(0, 0, 0, 0.2);
  border-color: transparent;
}

.lesson {
  background-color: white;
  height: 150px;
  border-radius: 8px;
  box-shadow: -1px 1px 3px 1px rgb(0 0 0 / 4%);
  overflow: hidden;
}

.lesson:hover {
  box-shadow: -3px 3px 7px 3px rgb(0 0 0 / 5%);
}

.lesson-top {
  height: 72%;
}

.lesson-bottom {
  height: 28%;
}

.learned {
  background-color: #2D30B1;
}

.not-learned {
  background-color: rgba(45, 48, 177, 0.4)
}

.progress {
  background-color: white;
  padding: 0;
  height: 10px;
  border: 1px solid grey;
  border-radius: 5px;
}

.progress-fill {
  height: 10px;
  background-color: grey;
  border-radius: 5px;
}

#buttons-container {
  height: 200px;
}

#method-nav {
  margin-top: 70px;
}

#method-links {
  height: 80px;
  border-bottom: 1.5px rgba(0, 0, 0, 0.3) solid;
}

#method-links a {
  line-height: 60px;
}

#method-nav a {
  font-size: 1.6rem;
}

#lessons {
  margin-bottom: 130px;
}

h2 {
  font-size: 1.8rem;
}

a {
  text-decoration: none !important;
  color: #0a0f6b;
  cursor: pointer;
  font-weight: 400 !important;
}

nav a {
  color: white !important;
}

p {
  text-shadow: none !important;
}

ul {
    list-style-type: none;
}

li:hover {
  cursor: pointer;
}

#custom-nav {
  background-color: #141BA6 !important;
  color: white;
  width: 100%;
  top: 0px;
  height: 80px;
  padding: 0 11%;
}

#profile {
  color: white !important;
  background-color: #1ED994 !important;
  padding: 11px 18px !important;
  border-radius: 6px !important;
  border: none !important;
}

.modal-dialog {
  height: 500px !important;
}

.modal-content {
  height: 100% !important;
}

input[type=range] {
  -webkit-appearance: none;
  margin: 10px 0;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
  height: 11px;
  cursor: pointer;
  animate: 0.2s;
  background: #F0F0F0;
  border-radius: 8px;
}
input[type=range]::-webkit-slider-thumb {
  height: 20px;
  width: 20px;
  border-radius: 50px;
  background: #D3D3D3;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -6px;
}
input[type=range]:focus::-webkit-slider-runnable-track {
  background: #F0F0F0;
}
input[type=range]::-moz-range-track {
  height: 11px;
  cursor: pointer;
  animate: 0.2s;
  background: #F0F0F0;
  border-radius: 8px;
}
input[type=range]::-moz-range-thumb {
  height: 20px;
  width: 20px;
  border-radius: 50px;
  background: #D3D3D3;
  cursor: pointer;
}
input[type=range]::-ms-track {
  height: 11px;
  cursor: pointer;
  animate: 0.2s;
}
input[type=range]::-moz-range-progress {
  color: red !important;
  border-radius: 10px;
}
input[type=range]::-ms-thumb {
  margin-top: 1px;
  height: 20px;
  width: 20px;
  border-radius: 50px;
  background: #D3D3D3;
  cursor: pointer;
}
input[type=range]:focus::-moz-range-progress {
  color: red !important;
}

.save {
  color: white !important;
  background-color: #1ED994 !important;
  border: none !important;
}

.save-disabled {
  background-color: lightgrey !important;
}

#profile-links {
  border-right: 1px solid lightgrey;
}

#method_logo {
  width: 150px;
  border: solid white 2px;
  border-radius: 10px;
}

#method-container {
  height: 200px;
}
</style>
